import bootstrap from 'bootstrap/dist/js/bootstrap.bundle'

const toastElList = [].slice.call(document.querySelectorAll('.toast'))
const toastList = toastElList.map(function (toastEl) {
  return new bootstrap.Toast(toastEl, {})
})

toastList.forEach((toast) => toast.show())

import '@fortawesome/fontawesome-free/js/solid'
import '@fortawesome/fontawesome-free/js/fontawesome'
